@import '../../../../scss/theme-bootstrap';

[data-component='search-gnav-input-field'] > .elc-spinner-wrapper {
  width: 30px;
  height: 20px;
}

.sd-search-gnav-input-field {
  .elc-gnav-search-button {
    color: $color-black;
    &::after,
    &::before {
      content: '';
      bottom: -4px;
      height: 1px;
      opacity: 0;
      position: absolute;
      width: calc(100% - 0.125em);
      #{$ldirection}: 2px;
    }
    &::after {
      background: currentColor;
      transition: width 0.25s ease-in-out;
    }
    &::before {
      color: $color-black;
      text-decoration: underline;
      text-underline-offset: $gnav-underline-offset;
    }
    &:hover::after {
      #{$ldirection}: auto;
      #{$rdirection}: 0.125em;
      width: 0;
    }
    &:hover:after,
    &:hover:before {
      opacity: 1;
    }
    .gnav-transparent--light & {
      color: $color-white;
    }
    .active-gnav .gnav-transparent--light &,
    .gnav-transparent--light.site-header-formatter--sticky & {
      color: $color-black;
    }
    .elc-search-icon {
      @include svg-icon-inline-mask('search.svg');
      height: 18px;
      width: 18px;
    }
  }
  .elc-search-active-screen-wrapper {
    @include transition(right 0.7s ease-in-out);
    #{$ldirection}: auto;
    #{$rdirection}: -100%;
    background-color: $color-white;
    border: 1px solid $color-light-gray;
    display: flex;
    flex-flow: column;
    padding: 0;
    width: 100;
    &.transition-active {
      #{$rdirection}: 0;
    }
    @include breakpoint($landscape-up) {
      width: 50%;
      &::after {
        #{$ldirection}: 0;
        background: $color-white-transparent-50;
        content: '';
        display: block;
        height: 100%;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: -1;
      }
    }
    > .elc-search-results-wrapper,
    > .elc-spinner-container {
      margin: 0;
      @include breakpoint($landscape-up) {
        grid-gap: 0;
        grid-template-areas: unset;
        grid-template-columns: 100%;
        grid-template-rows: min-content;
        > .elc-search-suggestion-container,
        > .elc-search-results-message-wrapper,
        > .elc-service-view-renderer {
          grid-area: unset;
        }
      }
    }
    > .elc-search-gnav-box {
      border-bottom: none;
      padding: 35px 25px 1px;
      @include breakpoint($landscape-up) {
        padding: 60px 60px 1px;
      }
      > .elc-search-close-icon-wrapper {
        padding: 2px;
        position: absolute;
        top: 20px;
        #{$rdirection}: 25px;
        @include breakpoint($landscape-up) {
          #{$rdirection}: 60px;
          top: 15px;
        }
        > span > .elc-search-close-icon {
          @include svg-icon-inline-mask('close.svg');
          background-color: $color-light-gray;
        }
      }
      > .elc-input-search-field {
        @include input-placeholder {
          color: $color-light-gray;
          font-family: $font--pretendard-light;
        }
        border-bottom: 1px solid $color-light-gray;
        border-radius: 0;
        color: $color-black;
        font-family: $font--pretendard-light;
        font-size: 16px;
        line-height: 1;
        padding: 17px 0px 12px;
        width: 100%;
      }
      > .elc-search-results-button {
        @include elc-button;
        @include elc-button--dark;
        display: inline-flex;
        height: 49px;
        z-index: 1000;
      }
    }
    > .elc-search-enhanced-wrapper,
    > .elc-search-results-wrapper {
      height: 100%;
      padding: 0 25px;
      width: 100%;
      @include breakpoint($landscape-up) {
        padding: 0 60px 60px;
      }
    }
    > .elc-search-enhanced-wrapper {
      flex-direction: column;
      > .elc-search-enhanced-trending-terms-wrapper {
        margin: 20px 0;
        width: auto;
        > .elc-search-enhanced-trending-title {
          @include secondary-heading;
        }
        > .elc-search-enhanced-trending-list {
          width: fit-content;
          > .elc-search-trending-term {
            width: fit-content;
            > .elc-search-trending-term__link {
              @include body-text;
              @include text-cta;
            }
          }
        }
      }
      > .elc-search-enhanced-grid-wrapper {
        margin: 0;
        width: 100%;
        > .elc-search-enhanced-grid-title {
          @include secondary-heading;
        }
      }
    }
    > .elc-search-results-wrapper {
      > .elc-search-suggestion-container {
        width: 100;
        > .elc-search-suggestion-wrapper {
          padding: 20px 0 0;
          > .elc-search-typeahead-title {
            @include secondary-heading;
            padding-bottom: 0;
          }
          > .elc-search-suggestion-value {
            @include body-text;
            @include text-cta;
            border-bottom: none;
            display: flex;
            padding: 10px 0 0;
            width: fit-content;
            > .elc-search-suggestion-link {
              &:hover {
                color: $color-black;
                text-decoration: none;
              }
            }
            > .elc-search-suggestion-link,
            > .elc-search-suggestion-link .elc-search-typeahead-value,
            > .elc-search-typeahead-count {
              @include body-text;
            }
            .elc-search-typeahead-value {
              margin-#{$rdirection}: 3px;
            }
          }
        }
      }
      > .elc-search-results-message-wrapper {
        > p {
          @include secondary-heading;
          margin: 12px 0;
        }
        > h5 {
          @include secondary-heading;
          text-align: center;
        }
      }
    }
  }
  .elc-grid-container {
    @include breakpoint($landscape-up) {
      margin: 0;
    }
    .elc-grid-item-product {
      padding: 0 0 30px;
      .elc-product-images-wrapper {
        min-height: auto;
        min-width: auto;
      }
    }
    .elc-product-short-description-wrapper {
      display: none;
    }
  }
}
